import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User, Promoter } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

import { Client, UserUpdateData } from 'services/apiClients';

@Injectable({ providedIn: 'root' })
export class UserService {

    constructor(
        private _httpClient: HttpClient,
        private _apiClient: Client,
    ) {
        let promotersData = localStorage.getItem('promoterActive');
        if (promotersData) {
            const storedPromoterObj = JSON.parse(promotersData);
            this._promoterActive.next(storedPromoterObj);
        }
    }

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _promoterActive: ReplaySubject<Promoter> = new ReplaySubject<Promoter>(1);

    set user(value: User) {
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    set promoterActive(value: Promoter) {
        this._promoterActive.next(value);
    }

    get promoterActive$(): Observable<Promoter> {
        return this._promoterActive.asObservable();
    }

    // get(): Observable<User> {
    //     return this._httpClient.get<User>('api/common/user').pipe(
    //         tap((user) => {
    //             this._user.next(user);
    //         })
    //     );
    // }

    hasRoles(userData: any, rolesIds: Array<any>) {
        return userData.roles.some((role: any) => rolesIds.includes(role.id));
    }

    updateUser(userID: number, user: UserUpdateData): Observable<any> {
        return this._apiClient.userUpdate(userID, user).pipe(
            map((response) => {
                const user = {
                    id: response.id,
                    name: response.name,
                    email: response.email,
                    roles: response.roles,
                    avatartext: response.name.substring(0, 1) + '' + response.surnames.substring(0, 1),
                    status: 'active'
                };
                this._user.next(user);
            })
        );
    }

    updatePromoterActive(promoter: any): void {
        localStorage.setItem('promoterActive', JSON.stringify(promoter));
        this._promoterActive.next(promoter);
    }
    
}
