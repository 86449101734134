import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, inject} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';

import { UserService } from 'app/core/user/user.service';

import { User } from 'app/core/user/user.types';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    
    exportAs: 'user',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;
    authService = inject(AuthService);
    
    user: User;
    
    @Input() showAvatar: boolean = true;
    accessGranted: boolean = false;

    accessPromoter: boolean = false;
    promoterUserActive: any;
    promotersUser: Array<any> = [];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        
        private _router: Router,
        private _userService: UserService
    ) {}

    ngOnInit(): void {

        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
            this.user = user;
            this.accessGranted = this._userService.hasRoles(this.user, [1]);
            this.accessPromoter = this._userService.hasRoles(this.user, [2,3]);

            if(this.accessPromoter) {
                let promotersData = localStorage.getItem('promoters');
                let promoterActiveData = localStorage.getItem('promoterActive');
                this.promotersUser = promotersData ? JSON.parse(promotersData) : [];
                this.promoterUserActive = promoterActiveData ? JSON.parse(promoterActiveData) : null;
            }

            
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    updateUserPromoter(promoter: any): void {
        // this._userService.updatePromoterActive(promoter);
        // 
        localStorage.setItem('promoterActive', JSON.stringify(promoter));
        this.promoterUserActive = promoter;
        window.location.reload();
    }

    signOut(): void {
        this.authService.signOut();
        location.reload();
    }

    routerLink(url: any) {
        this._router.navigate([url]);
    }

    goToProfile(params: boolean) {
        if(params) {
            localStorage.setItem('configuracionGeneral', 'true');
        } else {
            localStorage.removeItem('configuracionGeneral');
        }
        
        this._router.navigate(['/profile']);
    }
}
