import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

import { UserService } from 'app/core/user/user.service';
import { Client } from 'services/apiClients';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _apiClient: Client;

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    isLoggedIn(){
        const user = localStorage.getItem('user');
        console.log(user);
        if(user){
            this._authenticated = true;
        }else{
            this._authenticated = false;
        }
        return this._authenticated;
    }
    /*forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }*/

    /*signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }*/

    signInUsingToken(): Observable<any> {
        // this._apiClient.authMe().subscribe((response: any) => {
        //     console.log(response);
        // }, (error: any) => {
        //     console.log(error);
        // });
        this._authenticated = true;

        this._userService.user = JSON.parse(localStorage.getItem('user'));

        return of(true);
        // return this._httpClient
        //     .post('api/auth/sign-in-with-token', {
        //         accessToken: this.accessToken,
        //     })
        //     .pipe(
        //         catchError(() =>
        //             // Return false
        //             of(false)
        //         ),
        //         switchMap((response: any) => {
        //             // Replace the access token with the new one if it's available on
        //             // the response object.
        //             //
        //             // This is an added optional step for better security. Once you sign
        //             // in using the token, you should generate a new one on the server
        //             // side and attach it to the response object. Then the following
        //             // piece of code can replace the token with the refreshed one.
        //             if (response.accessToken) {
        //                 this.accessToken = response.accessToken;
        //             }

        //             // Set the authenticated flag to true
        //             this._authenticated = true;

        //             // Store the user on the user service
        //             this._userService.user = response.user;

        //             // Return true
        //             return of(true);
        //         })
        //     );
    }

    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpires');
        localStorage.removeItem('promoters');
        localStorage.removeItem('promoterActive');
        localStorage.removeItem('user');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /*signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }*/

    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
